@import './vendor/fontawesome-free/css/all.min.css';
@import 'bootstrap/dist/css/bootstrap.min.css';

/* 
* React Table Custom Css
*/
.ReactTable {
  position: relative;
  background-color: #ffffff;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.ReactTable .rt-table .rt-tr {
  display: flex;
  border-bottom: 1px #ebebeb;
}

.ReactTable .rt-table .rt-tr .rt-th {
  padding: 20px;
}

.font-size-2em {
  font-size: 2em;
}

.search {
  position: relative;
  box-shadow: 0 0 40px rgba(51, 51, 51, .1);
  margin-bottom: 20px;
}

.search input {
  height: 60px;
  text-indent: 25px;
  border: 2px solid #d6d4d4
}

.search input:focus {
  box-shadow: none;
  border: 2px solid blue
}

.search .fa-search {
  position: absolute;
  top: 20px;
  left: 16px
}

.search button {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 50px;
  width: 110px;
  background: blue;
  margin-top: 0px;
}

.custom-table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #dddddd;
}

.custom-table tr:nth-child(even) {
  background-color: #dddddd;
}

.custom-table td, th {
  text-align: center;
  padding: 8px;
  border: 1px solid rgb(233 233 233);
  border-collapse: collapse;
}

.rthfc .rt-td {
  background-color: rgba(255, 255, 255, 0);
}

.rthfc-td-fixed-left {
  background-color: #fff;
}

.rthfc-td-fixed-left-last {
  background-color: #fff;
  opacity: 0.1;
}

.span-center {
  text-align:center !important;
}

.center-icon {
  display: inline-block !important;
  width: 50% !important; 
}

.center-button {
  display: inline-block !important;
  width: 100% !important; 
}

.fill-color-modal {
  background-color: #f4f3ef;
}

.btn-add-lead{
  margin-top: 10px;
  float: right;
}

.pd-30 {
  padding: 30px;
}

/* .custom-table-tr-head{
  background-color:#ffffff
} */