/* *,
*:before,
*:after,
ul,
li,
a,
button,
input,
h1,
h2,
h3,
h4,
h5,
h6,
p,
img,
image,
svg,
path,
g,
canvas {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  text-decoration: none;
  font-family: "Arial";
  user-select: none;
  list-style: none;
  position: relative;
} */

/* body {
  background-color: #fafafa;
  margin: 0;
  padding: 100px;
  font-family: "Arial";
} */

.login-container {
  width: 400px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0 1px 25px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
}

.login-container .title {
  text-transform: capitalize;
  font-size: 1.6em;
  font-weight: 300;
  padding: 60px 0;
  padding-bottom: 10px;
}

.title-space {
  text-transform: capitalize;
  /* font-size: 1.6em;
  font-weight: 300; */
  padding: 60px 0;
  padding-bottom: 10px;
}

.login-container .login-button {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 60%;
  padding: 10px 10px;
  background-color: #007aff;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin: 40px 0;
}

.login-container .login-button:hover, .login-container .login-button:focus {
  box-shadow: 0px 3px 20px 3px rgba(0, 122, 255, 0.3);
  background-color: #007aff;
}
